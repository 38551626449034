import React from 'react';
import * as FeatherIcon from 'react-feather';
import { Redirect, Route } from 'react-router-dom';

import { getActiveUser, isUserAuthenticated } from '../helpers/authUtils';

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const IncubationDetails = React.lazy(() => import('../pages/apps/incubation/detail'));
const IncubationList = React.lazy(() => import('../pages/apps/incubation/registrationList'));
const AriseList = React.lazy(() => import('../pages/apps/arise/registrationList'));
const AriseDetails = React.lazy(() => import('../pages/apps/arise/detail'));
const AccelerationList = React.lazy(() => import('../pages/apps/acceleration/registrationList'));
const AccelerationDetails = React.lazy(() => import('../pages/apps/acceleration/detail'));
const AspiringUser = React.lazy(() => import('../pages/apps/aspiringUser/registrationList'));
const AspiringDetails = React.lazy(() => import('../pages/apps/aspiringUser/detail'));
const SchoolDetails = React.lazy(() => import('../pages/apps/schools-colleges/registrationList'));
const SchoolList = React.lazy(() => import('../pages/apps/schools-colleges/detail'));
const ExistingDetails = React.lazy(() => import('../pages/apps/existingUser/detail'));
const ExistingUser = React.lazy(() => import('../pages/apps/existingUser/registrationList'));
const ResearchDetails = React.lazy(() => import('../pages/apps/researchUser/detail'));
const ResearchUser = React.lazy(() => import('../pages/apps/researchUser/registrationList'));
const IndustryDetails = React.lazy(() => import('../pages/apps/IndustryUser/detail'));
const IndustryList = React.lazy(() => import('../pages/apps/IndustryUser/registrationList'));
const ExpertDetails = React.lazy(() => import('../pages/apps/expertUser/detail'));
const ExpertList = React.lazy(() => import('../pages/apps/expertUser/registrationList'));
const EDClubList = React.lazy(() => import('../pages/apps/edclubUser/registrationList'));
const EDClubDetails = React.lazy(() => import('../pages/apps/edclubUser/detail'));
const StudentList = React.lazy(() => import('../pages/apps/student/registrationList'));
const GuestList = React.lazy(() => import('../pages/apps/guest/registrationList'));
const MatchDetails = React.lazy(() => import('../pages/apps/matchMaking/detail'));
const MatchList = React.lazy(() => import('../pages/apps/matchMaking/registrationList'));
const InternshipDetails = React.lazy(() => import('../pages/apps/internship/detail'));
const InternshipList = React.lazy(() => import('../pages/apps/internship/registrationList'));
const EventDetails = React.lazy(() => import('../pages/apps/events/detail'));
const EventList = React.lazy(() => import('../pages/apps/events/registrationList'));
const SchemeList = React.lazy(() => import('../pages/apps/schemes/registrationList'));
const SchemeDetails = React.lazy(() => import('../pages/apps/schemes/detail'));
const ProgramDetails = React.lazy(() => import('../pages/apps/Programs/detail'));
const ProgramBookingDetails = React.lazy(() => import('../pages/apps/Programs/bookingDetail'));
const ProgramList = React.lazy(() => import('../pages/apps/Programs/registrationList'));
const NewProgram = React.lazy(() => import('../pages/apps/NewProgram/detail'));
const EditProgram = React.lazy(() => import('../pages/apps/NewProgram/editDetails'));
const NewsDetails = React.lazy(() => import('../pages/apps/latestNews/detail'));
const NewsList = React.lazy(() => import('../pages/apps/latestNews/registrationList'));
const DepartmentList = React.lazy(() => import('../pages/apps/departments/registrationList'));
const DepartmentDetails = React.lazy(() => import('../pages/apps/departments/detail'));
const NewUserList = React.lazy(() => import('../pages/apps/newUser/registrationList'));
const districtList = React.lazy(() => import('../pages/apps/districts/registrationList'));

const NewVideos = React.lazy(() => import('../pages/apps/Videos/registrationList'));
const NewMaterial = React.lazy(() => import('../pages/apps/Materials/registrationList'));
const NewTender = React.lazy(() => import('../pages/apps/Tenders/registrationList'));
const NewCareer = React.lazy(() => import('../pages/apps/Careers/registrationList'));
const NewMember = React.lazy(() => import('../pages/apps/Council/registrationList'));
const listEDClub = React.lazy(() => import('../pages/apps/edclub/registrationList'));
const DetailEDClub = React.lazy(() => import('../pages/apps/edclub/detail'));
const QuestionList = React.lazy(() => import('../pages/apps/Questions/registrationList'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
// dashboard
const Dashboard = React.lazy(() => import('../pages/dashboard'));

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }

            const loggedInUser = getActiveUser();
            // check if route is restricted by role
            if (roles && roles.indexOf(loggedInUser?.role) === -1) {
                // role not authorised so redirect to home page
                return <Redirect to={{ pathname: '/' }} />;
            }

            // authorised so return component
            return <Component {...props} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: PrivateRoute,
};

// dashboards
const dashboardRoutes = {
    path: '/dashboard',
    name: 'Dashboard',
    icon: FeatherIcon.Home,
    header: 'Menu',
    // badge: {
    //     variant: 'success',
    //     text: '1',
    // },
    component: Dashboard,
    roles: [9, 12, 13, 14, 15 , 16],
    route: PrivateRoute,
};

const edclubRoute = {
    name: 'ED Club',
    icon: FeatherIcon.Archive,
    children: [
        {
            path: '/edclub-list',
            name: 'ED CLub',
            component: listEDClub,
            route: PrivateRoute,
            roles: [9],
        },
       
    ],
};
const incubationRoute = {
    name: 'Incubation',
    icon: FeatherIcon.Archive,
    children: [
        {
            path: '/incubation-registration-list',
            name: 'Registrations ',
            component: IncubationList,
            route: PrivateRoute,
            roles: [9],
        },
        // {
        //     path: '/arise-registration-list',
        //     name: 'Arise',
        //     component: AriseList,
        //     route: PrivateRoute,
        //     roles: [9],
        // },
        // {
        //     path: '/acceleration-registration-list',
        //     name: 'Acceleration Program',
        //     component: AccelerationList,
        //     route: PrivateRoute,
        //     roles: [9],
        // },
    ],
};
const questionRoute = {
    name: 'Questions',
    icon: FeatherIcon.Archive,
    children: [
        {
            path: '/question-list',
            name: 'Question-List',
            component: QuestionList,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
        },
        // {
        //     path: '/arise-registration-list',
        //     name: 'Arise',
        //     component: AriseList,
        //     route: PrivateRoute,
        //     roles: [9],
        // },
        // {
        //     path: '/acceleration-registration-list',
        //     name: 'Acceleration Program',
        //     component: AccelerationList,
        //     route: PrivateRoute,
        //     roles: [9],
        // },
    ],
};

const iacRoute = {
    name: 'IAC',
    icon: FeatherIcon.Archive,
    children: [
        {
            path: '/matchmaking-list',
            name: 'Match-Making ',
            component: MatchList,
            route: PrivateRoute,
            roles: [9],
        },
        {
            path: '/internship-list',
            name: 'Internship',
            component: InternshipList,
            route: PrivateRoute,
            roles: [9],
        },
    ],
};
const eventRoute = {
    name: 'Events',
    icon: FeatherIcon.Archive,
    children: [
        {
            path: '/events-list',
            name: 'List Events ',
            component: EventList,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
        },
    ],
};
const careerRoute = {
    name: 'Careers',
    icon: FeatherIcon.Archive,
    children: [],
};
const memberRoute = {
    name: 'Council Members',
    icon: FeatherIcon.Archive,
    roles: [9],
    children: [
        {
            path: '/council-list',
            name: 'List Members ',
            component: NewMember,
            route: PrivateRoute,
            roles: [9],
        },
    ],
};

const tenderRoute = {
    name: 'Information',
    icon: FeatherIcon.Archive,
    roles: [9],
    children: [
        {
            path: '/tender-list',
            name: 'Tenders ',
            component: NewTender,
            route: PrivateRoute,
            roles: [9],
        },
        {
            path: '/career-list',
            name: 'Careers ',
            component: NewCareer,
            route: PrivateRoute,
            roles: [9],
        },
    ],
};
const resourceRoute = {
    name: 'Resources',
    icon: FeatherIcon.Archive,
    roles: [9],
    children: [
        {
            path: '/material-list',
            name: 'Materials ',
            component: NewMaterial,
            route: PrivateRoute,
            roles: [9],
        },
        {
            path: '/video-list',
            name: 'Videos ',
            component: NewVideos,
            route: PrivateRoute,
            roles: [9],
        },
    ],
};
const departmentRoute = {
    name: 'Departments',
    icon: FeatherIcon.Archive,
    roles: [9],
    children: [
        {
            path: '/department-list',
            name: 'List Departments',
            component: DepartmentList,
            route: PrivateRoute,
            roles: [9],
        },
    ],
};
const newsRoute = {
    name: 'Latest News',
    icon: FeatherIcon.Archive,
    roles: [9],
    children: [
        {
            path: '/news-list',
            name: 'All News ',
            component: NewsList,
            route: PrivateRoute,
            roles: [9],
        },
    ],
};
const schemeRoute = {
    name: 'Schemes',
    icon: FeatherIcon.Archive,
    children: [
        {
            path: '/scheme-list',
            name: 'List Schemes ',
            component: SchemeList,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
        },
    ],
};
// const materialRoute = {
//     name: 'Materials',
//     icon: FeatherIcon.Archive,
//     children: [
//         {
//             path: '/scheme-list',
//             name: 'List Schemes ',
//             component: SchemeList,
//             route: PrivateRoute,
//             roles: [9],
//         },
//     ],
// };
const ProgramRoute = {
    name: 'Programmes',
    icon: FeatherIcon.Archive,
    children: [
        {
            path: '/program-list',
            name: 'Program list ',
            component: ProgramList,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
        },
        {
            path: '/newprogram-list',
            name: 'New Program',
            component: NewProgram,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
        },
    ],
};
const edcRoute = {
    name: 'EDC',
    icon: FeatherIcon.Archive,
    children: [
        {
            path: '/incubation-registration-list',
            name: 'Yuva Bootcamp',
            component: IncubationList,
            route: PrivateRoute,
            roles: [9],
        },
        {
            path: '/arise-registration-list',
            name: 'Online Entrepreneurship Program',
            component: AriseList,
            route: PrivateRoute,
            roles: [9],
        },
    ],
};
const Users = {
    name: 'Users',
    icon: FeatherIcon.Archive,
    children: [
        {
            path: '/aspiring-user-list',
            name: 'Aspiring Entrepreneurs',
            component: AspiringUser,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
            perm: 2,
        },
        {
            path: '/schools-colleges-list',
            name: 'Schools & Colleges',
            component: SchoolDetails,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
            perm: 3,
        },
        {
            path: '/existing-list',
            name: 'Existing Entrepreneurs',
            component: ExistingUser,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
            perm: 4,
        },
        {
            path: '/research-list',
            name: 'Research & Academic Institutions',
            component: ResearchUser,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
            perm: 5,
        },
        {
            path: '/industry-list',
            name: 'Industry Associations',
            component: IndustryList,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
            perm: 6,
        },
        {
            path: '/expert-list',
            name: 'Experts & Mentors',
            component: ExpertList,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
            perm: 7,
        },
        {
            path: '/edclub-list',
            name: 'ED Club',
            component: EDClubList,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
            perm: 8,
        },
        {
            path: '/student-list',
            name: 'Students',
            component: StudentList,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
            perm: 9,
        },
        {
            path: '/guest-list',
            name: 'Guest',
            component: GuestList,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
            perm: 10,
        },
    ],
};
const newUser = {
    name: 'Admin',
    icon: FeatherIcon.Archive,
    roles: [9, 12],
    children: [
        {
            path: '/newuser-list',
            name: 'New User',
            component: NewUserList,
            route: PrivateRoute,
            roles: [9, 12],
        },
    ],
};
const districts = {
    name: 'District',
    icon: FeatherIcon.Archive,
    roles: [9],
    children: [
        {
            path: '/district-list',
            name: 'District List',
            component: districtList,
            route: PrivateRoute,
            roles: [9, 15 , 16],
        },
    ],
};

const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/register',
            name: 'Register',
            component: Register,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
    ],
};

const flattenRoutes = (routes) => {
    let flatRoutes = [
        {
            path: '/incubation-detail/:id',
            component: IncubationDetails,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
        },
        {
            path: '/arise-detail/:id',
            component: AriseDetails,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
        },
        {
            path: '/acceleration-detail/:id',
            component: AccelerationDetails,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
        },
        {
            path: '/aspiring-detail/:id',
            component: AspiringDetails,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
        },
        {
            path: '/school-detail/:id',
            component: SchoolList,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
        },
        {
            path: '/existing-detail/:id',
            component: ExistingDetails,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
        },
        
        {
            path: '/research-detail/:id',
            component: ResearchDetails,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
        },
        {
            path: '/industry-detail/:id',
            component: IndustryDetails,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
        },
        {
            path: '/expert-detail/:id',
            component: ExpertDetails,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
        },
        {
            path: '/matchmaking-detail/:id',
            component: MatchDetails,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
        },
        {
            path: '/internship-detail/:id',
            component: InternshipDetails,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
        },
        {
            path: '/event-detail/:id',
            component: EventDetails,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
        },
        {
            path: '/scheme-detail/:id',
            component: SchemeDetails,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
        },
        {
            path: '/program-detail/:id',
            component: ProgramDetails,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
        },
        {
            path: '/program-booking-detail/:id/:uid',
            component: ProgramBookingDetails,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
        },
        {
            path: '/news-detail/:id',
            component: NewsDetails,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
        },
        {
            path: '/department-detail/:id',
            component: DepartmentDetails,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
        },
        {
            path: '/edit-program/:id',
            component: EditProgram,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
        },
        {
            path: '/edclub-detail/:id',
            component: EDClubDetails,
            route: PrivateRoute,
            roles: [9, 12, 13, 14, 15 , 16],
        },
    ];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

const appRoutes = [
    Users,
    // edcRoute,
    newUser,
    // edclubRoute,
    eventRoute,
    schemeRoute,
    ProgramRoute,
    incubationRoute,
    // iacRoute,
    newsRoute,
    resourceRoute,
    tenderRoute,
    memberRoute,
    departmentRoute,
    districts,
    questionRoute,

];

const allRoutes = [rootRoute, dashboardRoutes, ...appRoutes, authRoutes];

const authProtectedRoutes = [dashboardRoutes, ...appRoutes];
const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, allFlattenRoutes };
